

.myBG {
    height: auto;
    background: #0b0c2a !important;
    background-size: cover;
	min-height: 100vh;
}

/*---------------------
  Helper CSS
-----------------------*/
.success {
	padding-top: 100px;
	padding-bottom: 100px;
	margin: auto;
	text-align: center;
	width: 50%;
	height: 57.3vh
}

.success .container{
	text-transform: uppercase;
	background: #198754;
	text-align: center;
	border-radius: 5px;
	width: max-content;
	color: white;
	padding: 16px;
}

.success .container a {
	text-decoration: none;
	font-weight: bold;
	background: #e53637;
	padding: 10px;
	border-radius: 5px;
	color: white;
}
.errmsg {
	background:#e53637;
	color: white;
	width: 50%;
	margin: auto;
	padding: 8px;
	font-size: 28px;
	font-weight: bold;
	text-align: center;
	border-radius: 5px;
}

.icon-point {
	color: #808080;
}

.section-title {
	margin-bottom: 30px;
}

.section-title h4,
.section-title h5 {
	color: #ffffff;
	font-weight: 600;
	line-height: 21px;
	text-transform: uppercase;
	padding-left: 20px;
	position: relative;
	font-family: "Oswald", sans-serif;
}

.section-title h4:after,
.section-title h5:after {
	position: absolute;
	left: 0;
	top: -6px;
	height: 32px;
	width: 4px;
	background: #e53637;
	content: "";
}

.section-title-right a {
	text-decoration: none;
	font-size: 12px;
}

.section-title-right a:hover {
	text-decoration: none;
	font-size: 12px;
	color: #b7b7b7
}

.set-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
}

.spad {
	padding-top: 100px;
	padding-bottom: 100px;
}


.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: #fff;
}
.product-category{
	font-size: 13px;
	font-weight: 700;
	color: #e53637;
	background: #ffffff;
	padding: 5px 14px 3px;
	margin-top: 5px;
	display: inline-block;
	-webkit-transition: all, 0.2s;
	-o-transition: all, 0.2s;
	transition: all, 0.2s;
	border-radius: 5px;
	text-decoration: none;
}
/* Helper end */

/* buttons */

.primary-btn {
	display: inline-block;
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	color: #ffffff;
	letter-spacing: 2px;
}

.primary-btn span {
	font-size: 18px;
	margin-left: 5px;
	position: relative;
	top: 3px;
}

.site-btn {
	font-size: 13px;
	color: #ffffff;
	background-color: #e53637;
	font-weight: 700;
	border: none;
	border-radius: 2px;
	letter-spacing: 2px;
	text-transform: uppercase;
	display: inline-block;
	padding: 12px 30px;
}

/* button end */

/*---------------------
  Login
-----------------------*/

.login {
	padding-top: 130px;
	padding-bottom: 120px;
}

.login__form {
	position: relative;
	padding-left: 145px;
}

.login__form:after {
	position: absolute;
	right: -14px;
	top: -40px;
	height: 330px;
	width: 1px;
	background: rgba(255, 255, 255, 0.2);
	content: "";
}

.login__form svg path {
	color: #b7b7b7;
	font-size: 20px;
	position: absolute;
	left: 15px;
	top: 13px;
}

.login__form h3 {
	color: #ffffff;
	font-weight: 700;
	font-family: "Oswald", sans-serif;
	margin-bottom: 30px;
}

.login__form form .input__item {
	position: relative;
	width: 430px;
	margin-bottom: 20px;
}

.login__form form .input__item:before {
	position: absolute;
	left: 50px;
	top: 10px;
	height: 30px;
	width: 1px;
	background: #b7b7b7;
	content: "";
}

.login__form form .input__item input {
	height: 50px;
	width: 100%;
	font-size: 15px;
	color: #b7b7b7;
	background: #ffffff;
	border: none;
	padding-left: 76px;
}

.login__form form .input__item input::-webkit-input-placeholder {
	color: #b7b7b7;
}

.login__form form .input__item input::-moz-placeholder {
	color: #b7b7b7;
}

.login__form form .input__item input:-ms-input-placeholder {
	color: #b7b7b7;
}

.login__form form .input__item input::-ms-input-placeholder {
	color: #b7b7b7;
}

.login__form form .input__item input::placeholder {
	color: #b7b7b7;
}

.login__form form .input__item span {
	color: #b7b7b7;
	font-size: 20px;
	position: absolute;
	left: 15px;
	top: 13px;
}

.login__form form .input__item button {
	position: absolute;
	right: 5px;
	background: none;
	border: none;
}

.login__form__logo {
	color: #b7b7b7;
	font-size: 20px;
	position: absolute;
	left: 15px;
	top: 13px;
}

.login__form form button {
	border-radius: 0;
	margin-top: 10px;
}

.login__form .forget_pass {
	font-size: 15px;
	color: #ffffff;
	display: inline-block;
	position: absolute;
	right: 60px;
	bottom: 12px;
	text-decoration: none;
}

.login__register {
	padding-left: 30px;
}

.login__register h3 {
	color: #ffffff;
	font-weight: 700;
	font-family: "Oswald", sans-serif;
	margin-bottom: 30px;
}

.login__register .primary-btn {
	background: #e53637;
	padding: 12px 42px;
	text-decoration: none;
}

.login__register .primary-btn:hover {
	color: #fff;
	background: #e53637;
	padding: 14px 44px;
	opacity: 0.8;
	text-decoration: none;
}

.login__social {
	padding-top: 52px;
}

.login__social__links {
	text-align: center;
}

.login__social__links span {
	color: #ffffff;
	display: block;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 30px;
}

.login__social__links ul li {
	list-style: none;
	margin-bottom: 15px;
}

.login__social__links ul li:last-child {
	margin-bottom: 0;
}

.login__social__links ul li a {
	color: #ffffff;
	display: block;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	width: 460px;
	padding: 14px 0;
	position: relative;
	margin: 0 auto;
	text-decoration: none;
}

.login__social__links ul li a.facebook {
	background: #4267b2;
}

.login__social__links ul li a.google {
	background: #ff4343;
}

.login__social__links ul li a.twitter {
	background: #1da1f2;
}

.login__social__links ul li a i {
	font-size: 20px;
	position: absolute;
	left: 32px;
	top: 14px;
}

/*---------------------
  Sign Up
-----------------------*/

.signup {
	padding-top: 20px;
	padding-bottom: 150px;
}

.signup .login__form:after {
	height: 450px;
}

.signup .login__form h5 {
	font-size: 15px;
	color: #ffffff;
	margin-top: 36px;
}

.signup .login__form h5 a {
	color: #e53637;
	font-weight: 700;
	text-decoration: none;	
}

.signup .login__social__links {
	text-align: left;
	padding-left: 40px;
}

.signup .login__social__links h3 {
	color: #ffffff;
	font-weight: 700;
	font-family: "Oswald", sans-serif;
	margin-bottom: 30px;
}

.signup .login__social__links ul li a {
	margin: 0;
	text-align: center;
}


/* Carousel */
.news {
	margin-top: 50px;
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
}

.news-container {
    margin: 5% 0;
    color: #fff;
}

.news-carousel-container {
    margin: 3%;
}

.news-container-heading {
    color: #fefefe;
    margin: 2% auto;
    text-align: center;
    font-weight: 700;
}

.news-carousel-img {
    height: 600px;
    width: 1400px;
    border-radius: 5px;
}


.news-caption-title{
    color: #ffffff;
	font-size: 42px;
	font-family: "Oswald", sans-serif;
	font-weight: 700;
	line-height: 52px;
	margin-top: 35px;
    text-align: left;
	margin-bottom: 8px;
	-webkit-transition: all, 0.4s;
	-o-transition: all, 0.4s;
	transition: all, 0.4s;
}

/* .news-carousel-category {
    font-size: 16px;
	font-weight: 700;
	color: #e53637;
	background: #ffffff;
	padding: 5px 14px 5px 14px;
	-webkit-transition: all, 0.2s;
	-o-transition: all, 0.2s;
	transition: all, 0.2s;
	display: inline-block;
	border-radius: 5%;
} */

.news-carousel-category a{
	font-size: 16px;
	font-weight: 700;
	color: #e53637;
	background: #ffffff;
	padding: 6px 14px 6px 14px;
	-webkit-transition: all, 0.2s;
	-o-transition: all, 0.2s;
	transition: all, 0.2s;
	display: inline-block;
	border-radius: 5%;
	text-decoration: none;
}

.news-carousel-category a:hover {
	font-size: 16px;
	font-weight: 700;
	color: #e53637;
	background: #ffffff;
	padding: 8px 16px 8px 16px;
	-webkit-transition: all, 0.2s;
	-o-transition: all, 0.2s;
	transition: all, 0.2s;
	display: inline-block;
	border-radius: 5%;
	opacity: 0.8;
	text-decoration: none;
}

.news-carousel-caption {
    position: relative;
    bottom: 1.25rem;
    padding-top: 1.25rem;
    width: 50%;
    margin-bottom: 50px;
	justify-content: left;
	text-align: left;
}

.news-carousel-caption a {
	position: relative;
	opacity: 20;
	-webkit-transition: all, 0.8s;
	-o-transition: all, 0.8s;
	transition: all, 0.8s;
}

.news-carousel-caption a span {
	font-size: 13px;
	color: #fff;
	background: #e53637;
	display: inline-block;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 10px 20px 10px 20px;
	border-radius: 5%;
	margin-right: 500px;
}

.news-carousel-caption a span:hover {
	font-size: 13px;
	color: #fff;
	background: #e53637;
	display: inline-block;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 12px 22px 12px 22px;
	border-radius: 5%;
	margin-right: 500px;
	opacity: 0.8;
}

.news-carousel-caption p {
}

/* Carousel end */



/* Product */


.product {
	padding-bottom: 60px;
	padding-top: 80px;
}



.product-page {
	padding-top: 60px;
}

.btn__all {
	text-align: right;
	margin-bottom: 30px;
}

.trending__product {
	margin-bottom: 50px;
}

.popular__product {
	margin-bottom: 50px;
}

.recent__product {
	margin-bottom: 50px;
}

.product__item {
	margin-bottom: 30px;
}

.product__item__pic {
	height: 234px;
	position: relative;
	border-radius: 5px;
}

.product__item__pic .ep {
	font-size: 13px;
	color: #ffffff;
	background: #e53637;
	display: inline-block;
	padding: 2px 12px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	top: 10px;
}

.product__item__pic .comment {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	bottom: 10px;
}

.product__item__pic .view {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.product__item__text {
	margin-top: 10px;
}

.product__item__text ul {
	margin-bottom: 0;
}

.product__item__text ul li {
	list-style: none;
	font-size: 10px;
	color: #ffffff;
	font-weight: 700;
	padding: 2px 10px;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 50px;
	display: inline-block;
}

.product__item__text p {
    font-size: 12px;
	font-weight: 700;
	color: #e53637;
	background: #ffffff;
	padding: 5px 14px 3px;
	margin-top: 5px;
	display: inline-block;
	-webkit-transition: all, 0.2s;
	-o-transition: all, 0.2s;
	transition: all, 0.2s;
	border-radius: 5px;
}

.product__item__text h5 a {
	color: #b7b7b7;
	font-weight: 700;
	line-height: 26px;
	text-decoration: none;
}

.product__sidebar .section-title h5 {
	color: #ffffff;
	font-weight: 600;
	font-family: "Oswald", sans-serif;
	line-height: 21px;
	text-transform: uppercase;
	padding-left: 20px;
	position: relative;
}

.product__sidebar .section-title h5:after {
	position: absolute;
	left: 0;
	top: -6px;
	height: 32px;
	width: 4px;
	background: #e53637;
	content: "";
}

.product__sidebar__view {
	position: relative;
	margin-bottom: 80px;
}

.product__sidebar__view .filter__controls {
	position: absolute;
	right: 0;
}

.product__sidebar__view .filter__controls li {
	list-style: none;
	font-size: 13px;
	color: #b7b7b7;
	display: inline-block;
	margin-right: 7px;
	cursor: pointer;
}

.product__sidebar__view .filter__controls li.active {
	color: #ffffff;
}

.product__sidebar__view .filter__controls li:last-child {
	margin-right: 0;
}

.product__sidebar__view__item {
	position: relative;
}

.product__sidebar__view__item img{
	width: 500%;
	position: relative;
	border-radius: 5px;
	margin-bottom: 20px;
}

.product__sidebar__view__item .ep {
	font-size: 13px;
	color: #ffffff;
	background: #e53637;
	display: inline-block;
	padding: 2px 12px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	top: 10px;
}

.product__sidebar__view__item .view {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	top: 10px;
}

.product__sidebar__view__item h5 {
	position: absolute;
	left: 0;
	bottom: 25px;
	width: 100%;
	padding: 0 30px 0 20px;
}

.product__sidebar__view__item h5 a {
	color: #ffffff;
	font-weight: 700;
	line-height: 26px;
}

.product__sidebar__comment {
	margin-bottom: 35px;
}

.product__sidebar__comment__item {
	margin-bottom: 20px;
	overflow: hidden;
}

.product__sidebar__comment__item__pic {
	float: left;
	margin-right: 15px;
}

.product__sidebar__comment__item__text {
	overflow: hidden;
}

.product__sidebar__comment__item__text ul {
	margin-bottom: 10px;
}

.product__sidebar__comment__item__text ul li {
	list-style: none;
	font-size: 10px;
	color: #ffffff;
	font-weight: 700;
	padding: 1px 10px;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 50px;
	display: inline-block;
}

.product__sidebar__comment__item__text h5 {
	margin-bottom: 10px;
}

.product__sidebar__comment__item__text h5 a {
	color: #ffffff;
	font-weight: 700;
	line-height: 26px;
}

.product__sidebar__comment__item__text span {
	display: block;
	font-size: 13px;
	color: #b7b7b7;
}

.product__page__title {
	border-bottom: 2px solid rgba(255, 255, 255, 0.2);
	padding-bottom: 10px;
	margin-bottom: 30px;
}

.product__page__title .section-title {
	margin-bottom: 0;
}

.product__page__title .product__page__filter {
	text-align: right;
}

.product__page__title .product__page__filter p {
	color: #ffffff;
	display: inline-block;
	margin-bottom: 0;
	margin-right: 16px;
}

.product__page__title .product__page__filter .nice-select {
	float: none;
	display: inline-block;
	font-size: 15px;
	color: #3d3d3d;
	font-weight: 700;
	border-radius: 0;
	padding-left: 15px;
	padding-right: 40px;
	height: 32px;
	line-height: 32px;
}

.product__page__title .product__page__filter .nice-select:after {
	border-bottom: 2px solid #111;
	border-right: 2px solid #111;
	height: 8px;
	top: 47%;
	width: 8px;
	right: 15px;
}

.product__page__title .product__page__filter .nice-select .list {
	margin-top: 0;
	border-radius: 0;
}

.product__pagination {
	padding-top: 15px;
}

.product__pagination a {
	display: inline-block;
	font-size: 15px;
	color: #b7b7b7;
	font-weight: 600;
	height: 50px;
	width: 50px;
	border: 1px solid transparent;
	border-radius: 50%;
	line-height: 48px;
	text-align: center;
	-webkit-transition: all, 0.3s;
	-o-transition: all, 0.3s;
	transition: all, 0.3s;
}

.product__pagination a:hover {
	color: #ffffff;
}

.product__pagination a.current-page {
	border: 1px solid #ffffff;
}

.product__pagination a i {
	color: #b7b7b7;
	font-size: 15px;
}


/* Product end */

/*---------------------
  Anime Details
-----------------------*/

.anime-details {
	padding-top: 60px;
}

.anime__details__content {
	margin-bottom: 65px;
}

.anime__details__text {
	position: relative;
}

.anime__details__text p {
	color: #b7b7b7;
	font-size: 18px;
	line-height: 30px;
}

.anime__details__pic {
	height: 440px;
	border-radius: 5px;
	position: relative;
}

.anime__details__pic .comment {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	bottom: 25px;
}

.anime__details__pic .view {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	right: 10px;
	bottom: 25px;
}

.anime__details__title {
	margin-bottom: 20px;
}

.anime__details__title h3 {
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 13px;
}

.anime__details__title span {
	font-size: 15px;
	color: #b7b7b7;
	display: block;
}

.anime__details__rating {
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
}

.anime__details__rating .rating i {
	font-size: 24px;
	color: #e89f12;
	display: inline-block;
}

.anime__details__rating span {
	display: block;
	font-size: 18px;
	color: #b7b7b7;
}

.anime__details__widget {
	margin-bottom: 15px;
}

.anime__details__widget ul {
	margin-bottom: 20px;
}

.anime__details__widget ul li {
	list-style: none;
	font-size: 15px;
	color: #ffffff;
	line-height: 30px;
	position: relative;
	padding-left: 18px;
}

.anime__details__widget ul li:before {
	position: absolute;
	left: 0;
	top: 12px;
	height: 6px;
	width: 6px;
	background: #b7b7b7;
	content: "";
}

.anime__details__widget ul li span {
	color: #b7b7b7;
	width: 115px;
	display: inline-block;
}

.anime__details__btn .follow-btn {
	font-size: 13px;
	color: #ffffff;
	background: #e53637;
	display: inline-block;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 14px 20px;
	border-radius: 4px;
	margin-right: 11px;
}

.anime__details__btn .watch-btn span {
	font-size: 13px;
	color: #ffffff;
	background: #e53637;
	display: inline-block;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 14px 20px;
	border-radius: 4px;
	margin-right: 1px;
}

.anime__details__btn .watch-btn i {
	font-size: 20px;
	display: inline-block;
	background: #e53637;
	padding: 11px 5px 16px 8px;
	color: #ffffff;
	border-radius: 0 4px 4px 0;
}

.anime__details__review {
	margin-bottom: 55px;
}

.anime__review__item {
	overflow: hidden;
	margin-bottom: 15px;
}

.anime__review__item__pic {
	float: left;
	margin-right: 20px;
	position: relative;
}

.anime__review__item__pic:before {
	position: absolute;
	right: -30px;
	top: 15px;
	border-top: 15px solid transparent;
	border-left: 15px solid #1d1e39;
	content: "";
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.anime__review__item__pic img {
	height: 50px;
	width: 50px;
	border-radius: 50%;
}

.anime__review__item__text {
	overflow: hidden;
	background: #1d1e39;
	padding: 18px 30px 16px 20px;
	border-radius: 10px;
}

.anime__review__item__text h6 {
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 10px;
}

.anime__review__item__text h6 span {
	color: #b7b7b7;
	font-weight: 400;
}

.anime__review__item__text p {
	color: #b7b7b7;
	line-height: 23px;
	margin-bottom: 0;
}

.anime__details__form form textarea {
	width: 100%;
	font-size: 15px;
	color: #b7b7b7;
	padding-left: 20px;
	padding-top: 12px;
	height: 110px;
	border: none;
	border-radius: 5px;
	resize: none;
	margin-bottom: 24px;
}

.anime__details__form form button {
	font-size: 11px;
	color: #ffffff;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
	background: #e53637;
	border: none;
	padding: 10px 15px;
	border-radius: 2px;
}


/*---------------------
  Anime Watching
-----------------------*/

.anime__watching {
	position: relative;
	left: 10%;
	padding: 8px;
	background: #000;
	width: 80%;
	border-radius: 5px;
}



.anime__watching__container {
	padding-top: 50px;
}

.anime__watching__img {
	width: 100%;
	height: 100%;
	
}

.anime__video__player {
	margin-bottom: 40px;
}

.anime__video__player video{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.anime__video__player .plyr--video {
	border-radius: 5px;
	background: transparent;
}

.anime__video__player .plyr audio,
.anime__video__player .plyr iframe,
.anime__video__player .plyr video {
	width: 102%;
}

.anime__video__player .plyr--full-ui.plyr--video .plyr__control--overlaid {
	display: block;
}

.anime__video__player .plyr--video .plyr__control.plyr__tab-focus,
.anime__video__player .plyr--video .plyr__control:hover,
.anime__video__player .plyr--video .plyr__control[aria-expanded=true] {
	background: transparent;
}

.anime__video__player .plyr--video .plyr__controls {
	background: transparent;
}

.anime__video__player .plyr--video .plyr__progress__buffer {
	color: transparent;
}

.anime__video__player .plyr--full-ui input[type=range] {
	color: #ffffff;
}

.anime__video__player .plyr__controls .plyr__controls__item.plyr__progress__container {
	position: absolute;
	left: 26px;
	bottom: 45px;
	width: calc(100% - 60px);
}

.anime__video__player .plyr__menu {
	margin-right: 70px;
}

.anime__video__player .plyr__controls .plyr__controls__item:first-child {
	position: absolute;
	left: 32px;
	bottom: 8px;
}

.anime__video__player .plyr__controls .plyr__controls__item:last-child {
	position: absolute;
	right: 32px;
	bottom: 8px;
}

.anime__video__player .plyr__volume {
	position: absolute;
	width: auto;
	left: 76px;
	bottom: 8px;
}

.anime__video__player .plyr__controls .plyr__controls__item.plyr__time {
	position: absolute;
	left: 106px;
	bottom: 12px;
}

.anime__video__player .plyr__control--overlaid {
	background: transparent;
	background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, transparent)));
}

.anime__video__player .plyr__control--overlaid svg {
	height: 60px;
	width: 50px;
}

.anime__details__episodes {
	margin-bottom: 35px;
}

.anime__details__episodes a {
	display: inline-block;
	font-size: 15px;
	color: #ffffff;
	background: rgba(255, 255, 255, 0.2);
	padding: 10px 20px;
	border-radius: 4px;
	margin-right: 15px;
	margin-bottom: 20px;
	-webkit-transition: all, 0.3s;
	-o-transition: all, 0.3s;
	transition: all, 0.3s;
}

.anime__details__episodes a:hover {
	color: #000000;
	background: #ffffff;
}


/*---------------------
  Footer
-----------------------*/

.footer {
	background: #070720;
	padding-top: 60px;
	padding-bottom: 40px;
	position: relative;
}

.page-up {
	position: absolute;
	left: 50%;
	top: -25px;
	margin-left: -25px;
}

.page-up a {
	display: inline-block;
	font-size: 36px;
	color: #ffffff;
	height: 50px;
	width: 50px;
	background: #e53637;
	line-height: 50px;
	text-align: center;
	border-radius: 50%;
}

.page-up a span {
	position: relative;
	top: 2px;
	left: -1px;
}

.footer__logo a, .footer__nav a, .footer__logo a:hover {
	text-decoration: none;
	text-transform: uppercase;
	color: #ffffff;
}

.footer__nav {
	text-align: center;
}

.footer__nav ul li {
	list-style: none;
	display: inline-block;
	position: relative;
	margin-right: 40px;
}

.footer__nav ul li:last-child {
	margin-right: 0;
}

.footer__nav ul li a {
	font-size: 15px;
	color: #b7b7b7;
	display: block;
	font-weight: 700;
}

.footer__copyright__text p{
	color: #b7b7b7;
	margin-bottom: 0;
	text-align: right;
}

.footer__copyright__text a {
	color: #e53637;
	text-decoration: none;
}


.section {
	padding: 2rem 0;
	width: 90vw;
	max-width: var(--max-width);
	margin: 0 auto;
}

/*---------------------
  Breadcrumb
-----------------------*/

.breadcrumb-option {
	padding-top: 35px;
}

.breadcrumb__links a {
	font-size: 15px;
	color: #ffffff;
	margin-right: 5px;
	display: inline-block;
	position: relative;
	text-decoration: none;
}

.breadcrumb__logo svg path {
	font-size: 15px;
	color: #b7b7b7;
	margin-right: 5px;
	display: inline-block;
	position: relative;
	text-decoration: none;
}

.breadcrumb__logo span {
	font-size: 15px;
	color: #b7b7b7;
	display: inline-block;
}

.breadcrumb__links a:hover {
	font-size: 15px;
	color: #e53637;
	margin-right: 5px;
	display: inline-block;
	position: relative;
	text-decoration: none;
}

.breadcrumb__links a i {
	margin-right: 5px;
	color: #e53637;
}

/* .breadcrumb__links a:after {
	position: absolute;
	right: -14px;
	top: 0;
	content: "";
	font-family: "FontAwesome";
} */

.breadcrumb__links span {
	font-size: 15px;
	color: #b7b7b7;
	display: inline-block;
}

/*---------------------
    Normal Breadcrumb
-----------------------*/

.normal-breadcrumb {
	height: 300px;
	/* display: -webkit-box;
	display: -ms-flexbox; */
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-image: url(../img/2.jpg);
	background-size: cover;
}

.set-background {
	/* background: url(../img/2.jpg) */
}

.normal__breadcrumb__text h2 {
	background: black;
	margin-left: 500px;
	margin-right: 500px;
	border-radius: 25px;
	padding: 5px;
	color: #ffffff;
	font-size: 48px;
	font-family: "Oswald", sans-serif;
	font-weight: 700;
	margin-bottom: 22px;
}

.normal__breadcrumb__text a {
	text-decoration: none;
}

.normal__breadcrumb__text p {
	margin-left: 300px;
	margin-right: 300px;
	border-radius: 25px;
	padding: 5px 30px 5px 30px;
	background: black;
	color: #ffffff;
	font-size: 24px;
	margin-bottom: 0;
	display: inline-block;
}



/* Detail Product */

.detail-product {
	margin-bottom: 20px;
}

.detail-product-img {
	width: 590px;
	position: relative;
	border-radius: 5px;
}

.detail-product-img .comment{
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	bottom: 10px;
}
.detail-product-img .view{
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	right: 10px;
	bottom: 10px;
}


.detail-product-text {
	color: #ffffff;
}

.detail-product-text h6 {
	color: #808080;
	margin-bottom: 50px;
}

.detail-product-text p {
	color: #808080;
	margin-right: 10px;
	display: inline;
}

.detail-product-text-btn a {
	color: #fff;
	background-color:#e53637;
	padding: 8px 24px 8px 24px;
	border-radius: 5px;
	text-decoration: none;
}

.detail-product-text a:hover {
	color: #fff;
	background-color:#e53637;
	padding: 8px;
	padding: 10px 26px 10px 26px;
	border-radius: 5px;
	opacity: 0.8;
	text-decoration: none;
}