.nav {
    background-color: #070720;
}

.nav-logo{
    font-size: 32px;
    width: 180px;
}
.nav-logo span{
    font-size: 32px;
}

.nav-container {
    width: 170px;
    justify-content: right;
}

svg path {
    color: #b7b7b7;
}

.nav-links-{
    background-color: #e53637;/*will change background-color of element */
    color: white; /*will change color of text within the element */
}

.nav-links:hover {
    background-color: #e53637; /*will change background-color of element on hover */
    color: white; /*will change color of text within the element on hover */
}

.nav-links-font {
    font-size: 18px;
}

.search__dropdown{
    position: absolute;
    top: 100%;
    z-index: 1000; 
    display: inline-block;
    max-width: 300px; 
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    padding: 5px;
}

/* .search__dropdown: empty {
    border: none;
} */

.search__dropdown__row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
}

